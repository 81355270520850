const $ = require("jquery")
console.log("Făcut cu 💘 la Incognito Concept || www.incognito.ro");

$(window).on('load', function () {
  $(".loading-mask").fadeOut(1000, function () {
    $(".loading-mask").remove();
    $("body").toggleClass('loading');
  });
});

window.map = {
  1: {
    1: {
      1: {
        key: "Relaxare + Oraș + Dimineața", value: 'pisica', text: "o Pisică"
      }, 2: {
        key: "Relaxare + Oraș + La apus", value: 'porumbel', text: "un Porumbel"
      }, 3: {
        key: "Relaxare + Oraș + Noaptea", value: 'bufnita', text: 'o Bufniță'
      }
    }, 2: {
      1: {
        key: "Relaxare + Pădure + Dimineața", value: 'cerb', text: 'un Cerb'
      }, 2: {
        key: "Relaxare + Pădure + La apus", value: 'bursuc', text: 'un Bursuc'
      }, 3: {
        key: "Relaxare + Pădure + Noaptea ", value: 'bufnita', text: 'o Bufniță'
      }
    }, 3: {
      1: {
        key: "Relaxare + Șosea + Dimineața", value: 'randunica', text: 'o Rândunică'
      }, 2: {
        key: "Relaxare + Șosea + La apus", value: 'calut', text: 'un Căluț'
      }, 3: {
        key: "Relaxare + Șosea + Noaptea", value: 'bufnita', text: 'o Bufniță'
      }
    }
  }, 2: {
    1: {
      1: {
        key: "Aventură + Oraș + Dimineața", value: 'catel', text: 'un Cățel'
      }, 2: {
        key: "Aventură + Oraș + La apus", value: 'veverita', text: 'o Veveriță'
      }, 3: {
        key: "Aventură + Oraș + Noaptea", value: 'raton', text: 'un Raton'
      }
    }, 2: {
      1: {
        key: "Aventură + Pădure + Dimineața", value: 'iepure', text: 'un Iepure'
      }, 2: {
        key: "Aventură + Pădure + La apus", value: 'vulpe', text: 'o Vulpe'
      }, 3: {
        key: "Aventură + Pădure + Noaptea ", value: 'lup', text: 'un Lup'
      }
    }, 3: {
      1: {
        key: "Aventură + Șosea + Dimineața", value: 'antilopa', text: 'o Antilopă'
      }, 2: {
        key: "Aventură + Șosea + La apus", value: 'calut', text: 'un Căluț'
      }, 3: {
        key: "Aventură + Șosea + Noaptea", value: 'ponei', text: 'un Ponei'
      }
    }
  }, 3: {
    1: {
      1: {
        key: "Explorare + Oraș + Dimineața", value: 'catel', text: 'un Cățel'
      }, 2: {
        key: "Explorare + Oraș + La apus", value: 'veverita', text: 'o Veveriță'
      }, 3: {
        key: "Explorare + Oraș + Noaptea", value: 'raton', text: 'un Raton'
      }
    }, 2: {
      1: {
        key: "Explorare + Pădure + Dimineața", value: 'caprioara', text: 'o Căprioară'
      }, 2: {
        key: "Explorare + Pădure + La apus", value: 'vulpe', text: 'o Vulpe'
      }, 3: {
        key: "Explorare + Pădure + Noaptea", value: 'liliac', text: 'un Liliac'
      }
    }, 3: {
      1: {
        key: "Explorare + Șosea + Dimineața", value: 'antilopa', text: 'o Antilopă'
      }, 2: {
        key: "Explorare + Șosea + La apus", value: 'calut', text: 'un Căluț'
      }, 3: {
        key: "Explorare + Șosea + Noaptea", value: 'unicorn', text: 'un Unicorn'
      }
    }
  }
}

var imported = document.createElement('script');
imported.src = 'https://www.googletagmanager.com/gtag/js?id=UA-41091684-2';
document.head.appendChild(imported);


window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'UA-41091684-2');

window.subscribeEmail = function ($form, data) {
  var formdata = new URLSearchParams(data).toString();
  $.ajax({
    type: "POST",
    url: $form.attr('action'),
    data: formdata,
    cache: false,
    dataType: 'jsonp',

    error: function (err) {
      console.log('error');
      console.log(err)
    },
    success: function (data) {
      console.log(data);
      if (data.euid != "success") {
        console.log('Error: ' + data.msg);
      } else {
        console.log("Success");
        isRacoonVisible = true;
      }
    }
  });
}
if($("form").length) {
  $("form").on('submit', function (e) {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());

    if (Object.keys(data).length !== 9) {
      window.alert('Nu ai completat toate campurile!');
      return false;
    }
    data.apikey = "906c368351fd1ded9fe7f2b1b1cde985-us6";
    var isRacoonVisible = false;
    var $form = $(form);
    subscribeEmail($form, data)


    var image_name = window.map[data.grupa1][data.grupa2][data.grupa3].value;
    var animal_name = window.map[data.grupa1][data.grupa2][data.grupa3].text;

    var img = $('<img />', {
      id: 'spirit', class: 'img-fluid', src: "/quiz/" + image_name + '.png', alt: image_name
    });

    img.attr('id', 'spirit');
    img.attr('style', 'max-width:375px');
    $('#valueInput').innerHTML = "";
    img.appendTo($('#valueInput'));

    $("#valueText").text(animal_name);
    $(".surpriza").show();

    gtag('event', 'submit', {
      'event_category': 'event',
      'event_label': 'submit',
      'value': 'click'
      });

    $('html, body').animate({
      scrollTop: $("#spirit").offset().top
    }, 250);


  });
}
const shareButton = document.querySelector('.share-button');
const shareDialog = document.querySelector('.share-dialog');
const closeButton = document.querySelector('.close-button');
if($('.share-button').length) {
  shareButton.addEventListener('click', event => {
    if (navigator.share) {
      console.log($("#spirit"));
      let url = document.getElementById('spirit').getAttribute('src');
      console.log()
      $.ajax({
        url: url,
        cache: false,
        xhrFields: {
          responseType: 'blob'
        },
        success: function (data) {
          console.log(data, url)

          var file = new File([data], "spirit_animal.png", {type: 'image/png'});
          var filesArray = [file];
          if (navigator.canShare && navigator.canShare({files: filesArray})) {
            navigator.share({
              files: filesArray
            });
          }


        },
        error: function () {

        }
      })
    } else {
      shareDialog.classList.add('is-open');
    }
  });

  closeButton.addEventListener('click', event => {
    shareDialog.classList.remove('is-open');
  });
}
